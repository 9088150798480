import React from 'react';
import { useGetUser } from '@fingo/lib/hooks';
import { hasShowSubRoutes } from '@fingo/lib/helpers/routes';
import Home from '../features/home/components/Home';

const useRouteSetter = () => {
  const user = useGetUser();

  if (!user) return [];
  return [
    {
      id: 'martina-home',
      label: 'Home',
      path: '/app/home',
      component: <Home />,
      disabled: false,
      show: true,
      showInNavbar: true,
    },
  ];
};

const useMartinaRoutes = () => {
  const routes = useRouteSetter();
  const user = useGetUser();
  if (!user) return [];
  return routes.filter(({ show, subRoutes }) => {
    if (subRoutes === undefined && show) return true;
    if (!show) return false;
    return hasShowSubRoutes(user, subRoutes);
  });
};

export default useMartinaRoutes;
