const breakpoints = {
  values: {
    xs: 0,
    s: 375,
    sm: 600,
    m: 750,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export default breakpoints;
