import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { Button, Container, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { ConditionalBorderStack, KeyValueStack } from '@fingo/lib/components/stacks';
import CardHeader from '@fingo/lib/components/headers/CardHeader';
import CardFooter from '@fingo/lib/components/footers/CardFooter';

const MobileCredentialsCard = ({
  id,
  title,
  subtitle,
  isOk,
  credentials,
  errorMessage,
  toggle,
}) => {
  const cardRef = useRef(null);
  const flipCard = () => {
    cardRef.current.toggle();
  };

  return (
    <Flippy style={{ backgroundColor: 'transparent' }} flipOnClick={false} ref={cardRef}>
      <FrontSide style={{ borderRadius: 18, padding: 0 }}>
        <ConditionalBorderStack id={id} showBorder={!isOk} onConditionalClick={toggle}>
          <CardHeader status={isOk} primaryText={title} />
          <Typography px={3} mb={1}>
            {subtitle}
          </Typography>
          <CardFooter id={id} errorMessage={errorMessage} success={isOk} onActionClick={toggle}>
            <Button
              onClick={flipCard}
              size="small"
              sx={{
                px: 0,
                borderBottom: '1.8px solid',
                borderRadius: 0,
                paddingBottom: 0.1,
              }}
            >
              <Typography>Ver detalle</Typography>
            </Button>
          </CardFooter>
        </ConditionalBorderStack>
      </FrontSide>
      <BackSide style={{ borderRadius: 18, padding: 0 }}>
        <ConditionalBorderStack id={id} showBorder={!isOk} onConditionalClick={toggle}>
          <CardHeader status={isOk} primaryText={title} />
          <Container sx={{ px: 3, mb: 1 }}>
            <KeyValueStack items={credentials} />
          </Container>
          <CardFooter id={id} errorMessage={errorMessage} success={isOk} onActionClick={toggle}>
            <Button onClick={flipCard} size="small" sx={{ px: 0 }} startIcon={<ArrowBack />}>
              Volver
            </Button>
          </CardFooter>
        </ConditionalBorderStack>
      </BackSide>
    </Flippy>
  );
};

MobileCredentialsCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isOk: PropTypes.bool.isRequired,
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  errorMessage: PropTypes.string.isRequired,
  toggle: PropTypes.func,
};

MobileCredentialsCard.defaultProps = {
  credentials: [],
  subtitle: '',
  toggle: null,
};

export default MobileCredentialsCard;
