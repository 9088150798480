import React, { useMemo } from 'react';

import Router from '@fingo/lib/components/layout/Router';
import { useGetUser } from '@fingo/lib/hooks';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useMartinaRoutes from '../hooks/useMartinaRoutes';

const App = () => {
  const user = useGetUser();
  const country = useGetCountryFromUrl();
  const routes = useMartinaRoutes().filter((route) => !route.disabled);
  return useMemo(() => {
    if (!user || !country) return <></>;
    return <Router routes={routes} depth={2} />;
  }, [user, routes, country]);
};

export default App;
