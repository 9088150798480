import React, { useMemo } from 'react';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import { useGetUser, useIsLogged, useDynamicFingoLogo } from '@fingo/lib/hooks';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();
  const fingoLogo = useDynamicFingoLogo();

  const navbar = useMemo(() => (
    <FingoNavbar>
      <LogoNavItem logo={fingoLogo} isLogged={isLogged} />
      {isLogged && user && <LoggedInDesktopNavItems />}
      {!isLogged && <LoggedOutNavItems title="Iniciar Sesión" />}
    </FingoNavbar>

  ), [isLogged, user]);
  return navbar;
};

export default DesktopNavbar;
