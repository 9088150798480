import React from 'react';
import DesktopNavbar from './navbar/DesktopNavbar';

const MartinaNavbar = () => (
  <>
    <DesktopNavbar />
  </>
);

export default MartinaNavbar;
