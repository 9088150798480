import { useTranslation } from 'react-i18next';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { getSiiCredentials } from '../features/home/helpers/credentials';

const useCompanyCredentials = () => {
  const selectedCompany = useSelectedCompany();
  const { t } = useTranslation();

  const nationalTaxService = t('National Tax Service acronym');

  return [getSiiCredentials(selectedCompany, nationalTaxService)];
};

export default useCompanyCredentials;
