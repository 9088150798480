import React, { useMemo } from 'react';
import { LoggedInNavItems } from '@fingo/lib/components/layout/navbar';
import { Stack } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { logOut } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { NavbarLink } from '@fingo/lib/components/links';
import useMartinaRoutes from '../../../hooks/useMartinaRoutes';

const LoggedInDesktopNavItems = () => {
  const client = useApolloClient();
  const martinaRoutes = useMartinaRoutes();
  const routes = useMemo(
    () => martinaRoutes.filter(({ showInNavbar }) => !!showInNavbar),
  );
  return (
    <Stack
      width="100%"
      direction="row"
      spacing={4}
      height={55}
      alignItems="center"
      justifyContent="space-between"
      ml={4}
    >
      <LoggedInNavItems routes={routes} />
      <Stack direction="row" spacing={1}>
        <NavbarLink
          id="logout"
          title="Cerrar sesión"
          onClick={() => { logOut(client); }}
        />
      </Stack>
    </Stack>
  );
};

export default LoggedInDesktopNavItems;
