/* eslint-disable import/prefer-default-export */
import { formatDayMonthYear } from '@fingo/lib/helpers';

export const getSiiCredentials = (company, nationalTaxService) => {
  const { hasSiiCredentials, siiCredentials } = company;
  const currentSiiCredentials = siiCredentials.at(-1);
  const credentials = hasSiiCredentials
    ? [
      {
        key: 'Rut empresa:',
        value: company.masterEntity.displayNationalIdentifier || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentSiiCredentials.updatedAt),
      },
    ]
    : [];
  return {
    id: 'sii-credentials',
    title: `Credenciales ${nationalTaxService}`,
    isOk: hasSiiCredentials,
    credentials,
    errorMessage: `Sin credenciales ${nationalTaxService}`,
  };
};
